var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',{ref:"modal",attrs:{"modal-header-title":_vm.headerTitle,"size":"lg"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"modal-header clearfix text-left"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-hidden":"true"},on:{"click":function($event){return _vm.handlerClose()}}},[_vm._v(" x ")]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.headerTitle))])])]},proxy:true},{key:"container",fn:function(){return [_c('validation-observer',{ref:"entry",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
var validated = ref.validated;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.action),expression:"action"}],staticClass:"bg-white popup-notify"},[_c('div',{staticClass:"form-group"},[(_vm.action)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:" row pl-0 justify-content-between"},[(_vm.action !== _vm.ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION)?_c('label',{staticClass:"col-xl-6 col-lg-6 col-sm-6 col-form-label"},[_vm._v(_vm._s(_vm.$t("announcements.title"))+" "),_c('span',{staticClass:"app-require"},[_vm._v("＊")])]):_vm._e(),_c('div',{staticClass:"col-xl-12 col-lg-12 col-sm-12"},[(_vm.action === _vm.ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION)?_c('div',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.entry.title))]):_c('app-input',{attrs:{"name":"タイトル","type":"string128","rules":"required"},model:{value:(_vm.entry.title),callback:function ($$v) {_vm.$set(_vm.entry, "title", $$v)},expression:"entry.title"}})],1)])]),(_vm.action !== _vm.ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION)?_c('validation-provider',{attrs:{"name":"本文","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row pl-0 justify-content-between"},[_c('label',{staticClass:"col-sm-6 col-lg-6 col-sm-6 col-form-label"},[_vm._v(_vm._s(_vm.$t("announcements.contents"))+" "),_c('span',{staticClass:"app-require"},[_vm._v("＊")])]),_c('div',{staticClass:"col-sm-12 align-self-center"},[(_vm.isShowSummernote)?_c('app-summernote',{ref:"summer",attrs:{"max-length":"5120","val":_vm.entry.contents,"upload-s3":true},on:{"input":function($event){_vm.entry.contents = $event}},model:{value:(_vm.entry.contents),callback:function ($$v) {_vm.$set(_vm.entry, "contents", $$v)},expression:"entry.contents"}}):_vm._e(),(invalid && validated)?_c('label',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)])])]}}],null,true)}):_c('div',{staticStyle:{"overflow":"auto"},domProps:{"innerHTML":_vm._s(_vm.entry.contents)}})],1):_vm._e()]),_c('div',{staticClass:"card-footer row justify-content-end"},[_c('button',{staticClass:"btn btn-default m-r-10",attrs:{"type":"button","disabled":!valid && validated},on:{"click":_vm.handlerCancelButton}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")]),(_vm.action === _vm.ANNOUNCEMENTS_MNG_CONSTANT.CREATE_ACTION)?_c('button',{staticClass:"btn btn-primary m-r-10",attrs:{"type":"button","disabled":!valid && validated},on:{"click":_vm.createOrUpdate}},[_vm._v(" "+_vm._s(_vm.$t('common.create'))+" ")]):_vm._e(),(_vm.action === _vm.ANNOUNCEMENTS_MNG_CONSTANT.EDIT_ACTION)?_c('button',{staticClass:"btn btn-primary m-r-10",attrs:{"type":"button","disabled":!valid && validated},on:{"click":_vm.createOrUpdate}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")]):_vm._e()])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }