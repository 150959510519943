<template>
  <div class="card card-body shadow-sm announce-mng-app">
    <app-modal-confirm
      ref="modal_show_confirm"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteAnnouncement()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="modal_mail_confirm"
      :modal-header-title="$t('announcements.confirm_title')"
      @confirmed="sendMail()"
    >
      <template v-slot:body>
        <p class="confirm-body" v-if="!checkSendMail">{{ $t("announcements.confirm_mail_first") }}</p>
        <p class="confirm-body" v-else>{{ $t("announcements.confirm_mail_again") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-notification
      @showDataPreview="showDataPreview"
      ref="modal_notification_preview"
      :dataPreview.sync="dataPreview"
      :headerTitle="headerTitle"
      :action="action"
      @actionTrigger="handlerActionTrigger"
    >
    </app-modal-notification>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.ANNOUNCEMENTS_MNG_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row mt-3">
            <div class="col-xl-4 col-lg-6 col-sm-6">
              <div class="form-group row">
                <div class="col-xl-12 col-lg-12 col-sm-12  mt-0">
                  <label class="align-self-center mb-0 mr-5">{{ filters.status_equal.label }}</label>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12  mt-3">
                <app-radio v-for="(item, index) in ANNOUNCEMENTS_MNG_CONSTANT.FILTER_STATUS"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="filters.status_equal.value"/>
              </div>
            </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-sm-6">
              <div class="form-group row">
                <div class="col-xl-12 col-lg-3 col-sm-3  mt-0">
                  <label>{{ filters.keyword_like.label }}</label>
                </div>
                <div class="col-xl-12 col-lg-9 col-sm-9  mt-0">
                  <app-input :name="filters.keyword_like.name" input-style="normal"
                             v-model="filters.keyword_like.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-status_enabled="props">
          <td class="app-align-middle">
            <p class="app-table-p app-cell-tooltip mb-0" >
              {{getStatusEnabled(props.row.status_enabled)}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-contents="props">
          <td class="app-align-middle">
            <p class="app-table-p app-cell-tooltip mb-0">{{strip(props.row.contents)}}</p>
          </td>
        </template>

        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common.create") }}
          </button>
        </template>

        <template v-slot:body-cell-actions="props">
          <td class="text-center app-p-none">
            <button
              @click="handleBtnPreviewClick(props.row)"
              class="btn btn-default btn-action"
            >
              {{ $t("answer.preview") }}
            </button>
            <button
              @click="sendMailConfirm(props.row)"
              class="btn btn-default btn-action"
            >
              {{ $t("announcements.mail") }}
            </button>
            <button
              @click="handleBtnUpdateClick(props.row)"
              class="btn btn-primary btn-action"
            >
              {{ $t("common.edit") }}
            </button>
            <button
              @click="deleteAnnouncementConfirm(props.row)"
              class="btn btn-danger btn-action"
            >
              {{ $t("common.delete") }}
            </button>
          </td>


        </template>

      </app-basic-table>
    </validation-observer>
  </div>
</template>


<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ANNOUNCEMENTS_MNG_CONSTANT} from "@constants/announcement_mng";
import AppModalNotification from "@components/_common/popup/AppModalNotification";

export default {
  name: "AnnouncementMngListPage",
  components: {
    "app-basic-table": AppBasicTable,
    AppModalNotification
  },
  data() {
    const id = this.$route.params.id
    return {
      idEditing: id,
      check: false,
      checkSendMail: false,
      announcementId: null,
      idCache: null,
      filters: {
        keyword_like: {
          name: "keyword",
          condition: "like",
          label: this.$t("announcements.keyword")
        },
        status_equal: {
          name: "status_enabled",
          condition: "equal",
          label: this.$t("restaurants.status_enabled")
        },
      },
      columns: [
        {name: "id", label: this.$t("common.id"), textAlign: 'text-center', sortable: true},
        {name: "title", label: this.$t("announcements.title"), sortable: true},
        {name: "contents", label: this.$t("announcements.contents")},
        {name: "created_at", label: this.$t("announcements.created_at"), textAlign: 'text-center', sortable: true},
        {name: "published_at", label: this.$t("announcements.published_at"), textAlign: 'text-center', sortable: true},
        {name: "actions", label: this.$t("common.actions"), textAlign: 'text-center'},
      ],
      meta: {},
      dataPreview : {
        title : "",
        contents : ""
      },
      action : 0,
      headerTitle: "",
    };
  },

  methods: {
    async handleBtnCreateClick() {
      this.action = ANNOUNCEMENTS_MNG_CONSTANT.CREATE_ACTION
      this.dataPreview = {
        title : "",
        contents : ""
      }
      await this.$refs.modal_notification_preview.resetValidate()
      this.headerTitle = "お知らせ作成"
      this.pushRouteWithAction();
    },
    async handleBtnUpdateClick(value) {
      const res = await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(value.id));
      this.check = res.data.announcement.length
      this.$refs.modal_notification_preview.resetData()
      if (this.check == 0) {
        this.action = 0
        setTimeout(() => this.$router.go(0),500);
      } else {
        this.action = ANNOUNCEMENTS_MNG_CONSTANT.EDIT_ACTION
        this.dataPreview = value
        this.headerTitle = "お知らせ編集"
        this.pushRouteWithAction();
      }
    },
    pushRouteWithAction() {
      const query = Object.assign({}, this.$route.query);
      query.action = this.action
      this.$refs.modal_notification_preview.show()
    },
    async handleBtnPreviewClick(value) {
      this.announcementId = value.id;
      const res = await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(this.announcementId));
      this.check = res.data.announcement.length
      this.$refs.modal_notification_preview.resetData()
      if (this.check == 0) {
        this.action = 0
        setTimeout(() => this.$router.go(0),500);
      } else {
        this.dataPreview = value;
        this.headerTitle = "お知らせ"
        this.action = ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION
        await this.$refs.modal_notification_preview.show()
      }
    },
    handlerActionTrigger() {
      this.action = 0
      this.onResetAllSearch()
    },
    async showDataPreview() {
      this.$refs.modal_notification_preview.hide();
    },
    deleteAnnouncementConfirm(value) {
      this.announcementId = value.id;
      this.$refs.modal_show_confirm.show();
    },
    async sendMailConfirm(value) {
      this.announcementId = value.id;
      const re = await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(this.announcementId));
      this.check = re.data.announcement.length
      if (this.check !== 0) {
        await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(this.announcementId)).then(res => {
          this.checkSendMail = res.data.announcement[0].published_at
        });
        this.$refs.modal_mail_confirm.show();
      } else {
        this.__noticeError("予期しないエラーが発生いたしました。");
        setTimeout(() => this.$router.go(0),500);
      }
    },
    async sendMail() {
      const re = await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(this.announcementId));
      this.check = re.data.announcement.length
      if (this.check !== 0) {
        await this.$request.get(this.ENDPOINT.UI_HELPER_SEND_MAIL(this.announcementId));
        this.checkSendMail = re.data.announcement[0].published_at
        await this.$router.go(0);
        this.$refs.modal_mail_confirm.hide();
      } else {
        this.__noticeError("予期しないエラーが発生いたしました。");
        setTimeout(() => this.$router.go(0),500);
      }
    },
    getStatusEnabled(status) {
      let data = ANNOUNCEMENTS_MNG_CONSTANT.STATUS.find(({id}) => id === status);
      return data.name
    },
    async deleteAnnouncement(){
      const res = await this.$request.delete(this.ENDPOINT.ANNOUNCEMENTS_MNG_DELETE(this.announcementId));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.$refs.modal_show_confirm.hide();
        await this.redirectDefaultValue();
        this.$router.go(0);
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    },
    strip(html){
      let doc = new DOMParser().parseFromString(html, 'text/html');
      let text = doc.body.textContent;
      if (text.trim().length > 30) {
        return text.trim().slice(0,30).concat('...')
      } else {
        return text.trim() || "...";
      }
    },
    onSearch() {

    },
    onResetAllSearch() {
      this.$refs.basic_table.getEntries();
      this.$router.push({
        name: this.$route.name,
        query: {
          'filters.status_enabled.equal': 'all',
        }
      }).catch(e => {
        console.log('no warning console');
      })
    },
    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.ANNOUNCEMENTS_MNG_LIST,
        query : {
          'filters.status_enabled.equal': 'all',
        }
      }).catch(e => {
        console.log('no warning console');
      })
    },
  },
}
</script>

<style>
  .announce-mng-app .app-table-container .table th:nth-child(1) {
    width: 4%;
  }

  .announce-mng-app .app-table-container .table th:nth-child(2) {
    width: 15%;
  }

  .announce-mng-app .app-table-container .table th:nth-child(3) {
    width: 24%;
  }

  .announce-mng-app .app-table-container .table th:nth-child(4) {
    width: 11%;
  }

  .announce-mng-app .app-table-container .table th:nth-child(5) {
    width: 11%;
  }

  .announce-mng-app .app-table-container .table th:nth-child(6) {
    width: 36%;
  }
  .announce-mng-app .btn-action {
    margin: 5px;
  }
</style>
