<template>
  <app-modal
    ref="modal"
    :modal-header-title="headerTitle"
    size="lg"
  >
    <template v-slot:header>
      <div class="modal-header clearfix text-left">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          @click="handlerClose()"
        >
          x
        </button>
        <span class="title">{{ headerTitle }}</span>
      </div>
    </template>
    <template v-slot:container>
      <validation-observer
        ref="entry"
        v-slot="{ passes, valid, validated }"
      >
      <div class="bg-white popup-notify" v-show="action">
        <div class="form-group">
          <div class="card-body" v-if="action">
            <div class="form-group">
              <div class=" row pl-0 justify-content-between">
                <label class="col-xl-6 col-lg-6 col-sm-6 col-form-label" v-if="action !== ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION">{{ $t("announcements.title") }}
                  <span class="app-require">＊</span>
                </label>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                  <div v-if="action === ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION" style="font-size: 20px;font-weight: bold">{{entry.title}}</div>
                  <app-input
                    v-else
                    name="タイトル"
                    v-model="entry.title"
                    type="string128"
                    rules="required"
                  />
                </div>
              </div>
            </div>
            <validation-provider
              name="本文"
              rules="required"
              v-slot="{ errors, invalid, validated }"
              v-if="action !== ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION"
            >
            <div class="form-group">
              <div class="row pl-0 justify-content-between">
                <label class="col-sm-6 col-lg-6 col-sm-6 col-form-label">{{ $t("announcements.contents") }}
                  <span class="app-require">＊</span>
                </label>
                <div class="col-sm-12 align-self-center">
                  <app-summernote v-if="isShowSummernote" max-length="5120" ref="summer"
                                   @input="entry.contents = $event" v-model="entry.contents" :val="entry.contents" :upload-s3="true">
                  </app-summernote>
                  <label v-if="invalid && validated" class="error">
                    {{ errors[0] }}
                  </label>
                </div>
              </div>
            </div>
            </validation-provider>
            <div v-else v-html="entry.contents" style="overflow: auto"></div>
          </div>
        </div>
        <div class="card-footer row justify-content-end">
          <button type="button" class="btn btn-default m-r-10" :disabled="!valid && validated" @click="handlerCancelButton">
            {{ $t('common.close') }}
          </button>

          <button class="btn btn-primary m-r-10" type="button" :disabled="!valid && validated" v-if="action === ANNOUNCEMENTS_MNG_CONSTANT.CREATE_ACTION" @click="createOrUpdate">
            {{$t('common.create')}}
          </button>

          <button class="btn btn-primary m-r-10" type="button" :disabled="!valid && validated" v-if="action === ANNOUNCEMENTS_MNG_CONSTANT.EDIT_ACTION" @click="createOrUpdate">
            {{$t('common.save')}}
          </button>
        </div>
      </div>
      </validation-observer>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "../../../components/_common/popup/AppModal";
import AppBasicTable from "@components/_common/list/AppBasicTable";
import AppSummerNote from "../../../components/forms/AppSummernote";
import { ANNOUNCEMENTS_MNG_CONSTANT } from "@constants/announcement_mng";

export default {
  name: "AppModalNotification",
  components: {
    AppModal,
    "app-basic-table": AppBasicTable,
    AppSummerNote,
  },
  data() {
    return {
      loading: true,
      entry : {
        title : "",
        contents : ""
      },
      isShowSummernote : false
    }
  },
  props: {
    dataPreview: {
      type: Object,
    },
    action : {
      type: Number,
    },
    headerTitle: {
      type: String,
      default: "",
    }
  },
  async created() {
    if (this.action === ANNOUNCEMENTS_MNG_CONSTANT.EDIT_ACTION) {
      let params = _.cloneDeep(this.entry);
      this.loading = false;
      await this.$request.get(this.ENDPOINT.ANNOUNCEMENTS_MNG_EDIT(this.entry.id), params).then(res => {
        this.entry = res.data.data
        this.loading = true;
      })
    }
  },
  methods: {
    hide() {
      this.$refs.modal.hide();
      this.isShowSummernote = false;
      this.$refs.summer.register();
    },
    show() {
      $(".note-link-url").val("")
      $(".note-link-text").val("")
      this.$refs.modal.show();
      this.$refs.entry.reset();
      this.isShowSummernote = true
    },
    handlerCancelButton() {
      this.$refs.modal.hide();
      this.$refs.entry.reset();
    },
    redirectBack() {
      this.$router.go(-1);
    },
    handlerClose() {
      // $('#summernote').summernote('reset');
      this.isShowSummernote = false;
      this.$refs.summer.register();
    },
    async createOrUpdate() {
      if (!await this.$refs.entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.action === ANNOUNCEMENTS_MNG_CONSTANT.CREATE_ACTION) {
        res = await this.$request.post(this.ENDPOINT.ANNOUNCEMENTS_MNG_CREATE, params);
        msg = this.$t("common.msg create ok")
      } else {
        res = await this.$request.patch(this.ENDPOINT.ANNOUNCEMENTS_MNG_EDIT(this.entry.id), params);
        msg = this.$t("common.msg update ok")
      }
      if (res.hasErrors()) {
        if (res.data.error_msg !== '本文を入力してください。') {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        }
      } else {
        this.__noticeSuccess(msg);
        this.hide();
        this.$emit("actionTrigger")
      }
    },
    resetSummernote() {
      this.$refs.entry.reset();
    },
    async resetValidate() {
      await this.$refs.entry.reset();
    },
    resetData() {
      this.entry = _.cloneDeep(this.dataPreview);
      $('#summernote').summernote("code", this.entry.contents);
    },
  },
  watch: {
    action : {
      handler: async function (after, before) {
        if(after) {
          this.show();
        }
      },
      deep: true,
    },
    dataPreview : {
      handler: async function (after, before) {
        this.entry =  _.cloneDeep(after);
        $('#summernote').summernote("code", this.entry.contents);
      },
    },
  },
  updated() {
    if (this.action === ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION){
      $('#summernote').summernote('disable');
    } else {
      $('#summernote').summernote('enable');
    }
  }
}
</script>

<style>
  .popup-notify .note-modal-body .sn-checkbox-open-in-new-window,
  .popup-notify .note-modal-body .sn-checkbox-use-protocol {
    display: none;
  }
  .popup-notify .note-modal-footer {
      padding: 0 30px 20px;
      text-align: right;
      height: inherit;
  }
  .popup-notify .note-modal-body {
      padding: 20px 30px 0;
  }
  .popup-notify .note-modal-footer .note-btn {
      float: inherit;
      position: inherit !important;
  }
  .popup-notify .checkbox input[type="checkbox"] {
      opacity: 1;
      width: auto;
  }
  .popup-notify .note-modal-body .note-btn-primary {
    border: none;
    transition: inherit;
  }
  .popup-notify .note-modal-body .note-btn-primary.focus, .note-btn-primary:focus, .note-btn-primary:hover {
    border-radius: 4px;
    border: none;
  }
  .popup-notify iframe {
    width: 100%;
    height: 100%;
  }
</style>
